import React, { useState, useEffect, PropsWithChildren } from 'react';
import ShopifyBuy from 'shopify-buy';
import { Context } from './Context';
import { LocalStorage, LocalStorageKeys } from './utils';
import { ShopifyProviderProps } from './types';

export function ShopifyProvider({ shopName, accessToken, children }: PropsWithChildren<ShopifyProviderProps>) {
	const initialCart = LocalStorage.getInitialCart();
	const [cart, setCart] = useState<ShopifyBuy.Cart | null>(initialCart);

	const client = ShopifyBuy.buildClient({
		storefrontAccessToken: accessToken,
		domain: shopName,
	});

	useEffect(() => {
		async function getNewCart() {
			const newCart = await client.checkout.create();
			setCart(newCart);
		}

		async function refreshExistingCart(cartId: string) {
			try {
				const refreshedCart = await client.checkout.fetch(cartId);

				if (refreshedCart == null) {
					return getNewCart();
				}

				const cartHasBeenPurchased = refreshedCart.completedAt != null;

				if (cartHasBeenPurchased) {
					getNewCart();
				} else {
					setCart(refreshedCart);
				}
			} catch (error) {
				console.error(error);
			}
		}

		if (cart == null) {
			getNewCart();
		} else {
			refreshExistingCart(String(cart.id));
		}
	}, []);

	useEffect(() => {
		LocalStorage.set(LocalStorageKeys.CART, JSON.stringify(cart));
	}, [cart]);

	return (
		<Context.Provider
			value={{
				client,
				cart,
				setCart,
			}}
		>
			{children}
		</Context.Provider>
	);
}
