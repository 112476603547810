import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './client';
import { ShopifyProvider } from '../shopify';

export const wrapRootElement = ({ element }) => (
	<ApolloProvider client={client}>
		<ShopifyProvider
			shopName={process.env.GATSBY_SHOPIFY_STORE_DOMAIN}
			accessToken={process.env.GATSBY_STOREFRONT_ACCESS_TOKEN}
		>
			{element}
		</ShopifyProvider>
	</ApolloProvider>
);
