import {
	ApolloClient,
	ApolloLink,
	InMemoryCache,
} from '@apollo/client';
import { createHttpLink } from '@apollo/client/link/http';
import { onError } from '@apollo/client/link/error';

// custom error handling, only logging errors atm
const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		// do something with graphql error
		console.error({ graphQLErrors });
	}

	if (networkError) {
		// do something with network error
		console.error({ networkError });
	}
});

const httpLink = createHttpLink({
	uri: `https://${process.env.GATSBY_SHOPIFY_STORE_DOMAIN}/api/2022-04/graphql.json`,
	headers: {
		'Content-Type': 'application/json',
		'X-Shopify-Storefront-Access-Token': process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
		'Accept': 'application/json',
	},
});

const cache = new InMemoryCache();

const link = ApolloLink.from([
	errorLink,
	httpLink,
]);

export const client = new ApolloClient({
	link,
	cache,
});
